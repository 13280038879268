import React, {useState} from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Pagination,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";
import articles from "./blogArticlesLinks.json";
import Tires from "../../assets/webp/tires.webp";
import DriverRest from "../../assets/webp/driver-rest.webp";
import OldTruck from "../../assets/webp/old-truck.webp";
import Mechanics from "../../assets/webp/mechanics.webp";
import WrongPreventive from "../../assets/webp/wrong-preventive.webp";
import TruckNight from "../../assets/webp/truck-night.webp";
import TruckStop from "../../assets/webp/truck-stop.webp";
import TruckShop from "../../assets/webp/best-truck-shop.webp";
import EquipTruck from "../../assets/webp/equip-truck.webp";
import BestTruckRepairOrlando from "../../assets/webp/best-truck-repair-orlando.webp";
import TruckMaintenance from "../../assets/webp/truck-maintenance.webp";
import TruckWashing from "../../assets/webp/truck-washing.webp";
import TruckWebsite from "../../assets/webp/truck-website.webp";
import TruckDriver from "../../assets/webp/truck-driver.webp";
import FullTruckLoad from "../../assets/webp/full-truck-load.webp";
import Trucks from "../../assets/webp/freight-broker-vs-dispatcher.webp";
import TruckMaintenance2 from "../../assets/webp/truck-maintenance-2.webp";
import Image1 from "../../assets/carousel/1.webp";
import Image2 from "../../assets/carousel/2.webp";
import Image4 from "../../assets/carousel/4.webp";
import Image5 from "../../assets/carousel/5.webp";
import Image6 from "../../assets/carousel/6.webp";
import Image7 from "../../assets/carousel/7.webp";
import Image8 from "../../assets/carousel/8.webp";
import Image9 from "../../assets/carousel/9.webp";
import Image10 from "../../assets/carousel/10.webp";
import Image11 from "../../assets/carousel/11.webp";
import Image12 from "../../assets/carousel/12.webp";
import Image13 from "../../assets/carousel/13.webp";
import Image14 from "../../assets/carousel/14.webp";
import Image19 from "../../assets/carousel/19.webp";
import Image20 from "../../assets/carousel/20.webp";
import Image21 from "../../assets/carousel/21.webp";
import Image22 from "../../assets/carousel/22.webp";
import Image23 from "../../assets/carousel/23.webp";
import Image25 from "../../assets/carousel/25.webp";
import Image33 from "../../assets/carousel/33.webp";
import Image34 from "../../assets/carousel/34.webp";
import TruckDriverCellphone from "../../assets/webp/truck-driver-cellphone.webp";
import CESLogo from "../../assets/webp/Trucking-Logo-01.webp";
import FTLVSLTL from "../../assets/webp/FTL-vs-LTL.webp";

const BlogSection = () => {
  const { lang, blogPage, changeBlogPage } = useUiStore();

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(blogPage);
  // No of Records to be displayed on each page   
  const [recordsPerPage, setRecordsPerPage] = useState(9);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = articles.articles.slice(indexOfFirstRecord, 
    indexOfLastRecord);
  const nPages = Math.ceil(articles.articles.length / recordsPerPage)

  const handlePageChange = (event, page) => {
    changeBlogPage(page);
    setCurrentPage(page);
  }

  return (
    <>
      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair Blog
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Top 3 Truck Stops in Orlando Florida
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        How to Choose The Best Truck and Trailer Repair Shop in Orlando
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Learn How to Equip The Mobile Service Truck
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Best Truck Repair in Orlando
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Top 9 Truck Maintenance Tips
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Trailer Washing: 5 Maintenance Advantages
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Top 10 Best Truck Repair Websites
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        10 Common Truck Breakdowns and How to Fix Them
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        The 7 Best Pickup Truck Mechanic Near Me
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Mobile Repair and Maintenance Service for your truck now
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Best Mobile Truck Repair in Orlando, Florida
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        How To Choose The Best Truck Workshop: 10 Things To Keep In Mind
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Top 10 Apps for Truck Drivers
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Freight Broker vs Dispatcher: Choose the Best One
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        What are Full Truckloads?
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Failing engine: 10 symptoms that you should not ignore
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        The 10 Essential Maintenance Tips For Your Truck
      </Typography>

      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* Títulos */}
        <Grid container sx={{ flexGrow: 1 }} spacing={1}>
          {/* Title */}
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric",
              }}
            >
              {lang === "EN" ? "The truck blog" : "Nuestro blog de camiones"}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric",
                },
              }}
            >
              {lang === "EN" ? "The truck blog" : "Nuestro blog de camiones"}
            </Typography>
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
            {/* Subtitle Desktop View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
                fontSize: 20,
                mb: 10,
              }}
            >
              {lang === "EN" ? (
                <>
                  Check this info to learn some tricks about{" "}
                  <b style={{ color: "#D69E48" }}>
                    mechanic repairment, electrical service and many more
                  </b>
                  ...{" "}
                </>
              ) : (
                <>
                  Te dejamos esta información para aprender acerca de{" "}
                  <b style={{ color: "#D69E48" }}>
                    reparación mecánica, servicio eléctrico y muchos más
                  </b>
                  ...{" "}
                </>
              )}
            </Typography>

            {/* Subtitle Tablet and Phone View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 20,
                mb: 5,
              }}
            >
              {lang === "EN" ? (
                <>
                  Check this info to learn some tricks about{" "}
                  <b style={{ color: "#D69E48" }}>
                    mechanic repairment, electrical service and many more
                  </b>
                  ...{" "}
                </>
              ) : (
                <>
                  Te dejamos esta información para aprender acerca de{" "}
                  <b style={{ color: "#D69E48" }}>
                    reparación mecánica, servicio eléctrico y muchos más
                  </b>
                  ...{" "}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {/* Cards de cada artículo */}

          {currentRecords.map((article, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Link
                to={"/blog/" + article.url}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Card sx={{ height: 470 }}>
                  <CardMedia
                    component="img"
                    alt={lang === "EN" ? article.eng_title : article.esp_title}
                    height="200"
                    image={
                      article.position === 0
                        ? Tires
                        : article.position === 1
                        ? DriverRest
                        : article.position === 2
                        ? OldTruck
                        : article.position === 3
                        ? Mechanics
                        : article.position === 4
                        ? WrongPreventive
                        : article.position === 5
                        ? TruckNight
                        : article.position === 6
                        ? TruckStop
                        : article.position === 7
                        ? TruckShop
                        : article.position === 8
                        ? EquipTruck
                        : article.position === 9
                        ? BestTruckRepairOrlando
                        : article.position === 10
                        ? TruckMaintenance
                        : article.position === 11
                        ? TruckWashing
                        : article.position === 12
                        ? TruckWebsite
                        : article.position === 13
                        ? Tires
                        : article.position === 14
                        ? OldTruck
                        : article.position === 15
                        ? Mechanics
                        : article.position === 16
                        ? WrongPreventive
                        : article.position === 17
                        ? TruckNight
                        : article.position === 18
                        ? TruckDriver
                        : article.position === 19
                        ? Trucks
                        : article.position === 20
                        ? FullTruckLoad
                        : article.position === 21
                        ? DriverRest
                        : article.position === 22
                        ? TruckMaintenance2
                        : article.position === 23
                        ? Image1
                        : article.position === 24
                        ? Image2
                        : article.position === 25
                        ? Tires
                        : article.position === 26
                        ? FullTruckLoad
                        : article.position === 27
                        ? TruckWashing
                        : article.position === 28
                        ? Image4
                        : article.position === 29
                        ? Image5
                        : article.position === 30
                        ? Image6
                        : article.position === 31
                        ? Image7
                        : article.position === 32
                        ? Image8
                        : article.position === 33
                        ? Image9
                        : article.position === 34
                        ? Image10
                        : article.position === 35
                        ? Image11
                        : article.position === 36
                        ? Image25
                        : article.position === 37
                        ? Image22
                        : article.position === 38
                        ? Image12
                        : article.position === 39
                        ? Image13
                        : article.position === 40
                        ? Image14
                        : article.position === 41
                        ? Image33
                        : article.position === 42
                        ? Image34
                        : article.position === 43
                        ? Image19
                        : article.position === 44
                        ? TruckDriverCellphone
                        : article.position === 45
                        ? Image23
                        : article.position === 46
                        ? Image20
                        : article.position === 47
                        ? Image21
                        : Tires
                    }
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h2"
                      sx={{
                        fontSize: 25,
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      }}
                      component="div"
                    >
                      {lang === "EN" ? article.eng_title : article.esp_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="truncate"
                    >
                      {lang === "EN"
                        ? article.eng_metadescription
                        : article.esp_metadescription}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button size="small">Share</Button>
                     */}
                    <Button size="small">
                      {lang === "EN" ? "Learn More" : "Leer más"}
                    </Button>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          ))}

          <Grid item xs={12} sx={{ mt: 5 }}>
            <Pagination page={blogPage} count={nPages} onChange={handlePageChange}/>
          </Grid>

          <Grid item xs={12} sx={{ mt: 5 }} textAlign="center">
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>Go to home</>
                ) : (
                  <>Volver a página principal</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BlogSection;
