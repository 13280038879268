import React from "react";
import { Container, Box, Grid, Typography } from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import MapIcon from "@mui/icons-material/Map";
import RestoreIcon from "@mui/icons-material/Restore";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";

const StatisticsSection = () => {
  const { lang } = useUiStore();
  return (
    <Box
      alt="Mechanical Truck Services"
      title="Mechanical Truck Services"
      sx={{
        height: "auto",
        backgroundColor: "#FDD383",
        // backgroundImage:
        // "linear-gradient( 136deg, #D69E48 0%, #FDD383 50%, #FDD383 100%)",
        border: 0,
        color: "black",
        paddingTop: 10,
        paddingBottom: 10,
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box textAlign={"center"}>
          <Grid container sx={{ flexGrow: 1 }} spacing={5}>
            {/* Title */}
            <Grid item xs={12} sm={12} md={4} textAlign="center">
              <Typography>
                <MapIcon sx={{ fontSize: 50 }} />
                <br />
                <b style={{ fontFamily: "Ethnocentric", fontSize: 30 }}>100</b>
                <b
                  style={{
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    paddingLeft: "5px",
                  }}
                >
                  miles
                </b>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                {lang === "EN" ? (
                  <>
                    Our service covers{" "}
                    <b>100 miles around Orlando, Jacksonville, and Tampa</b>,
                    ensuring rapid and reliable roadside assistance whenever and
                    wherever you need it.
                  </>
                ) : (
                  <>
                    Nuestro servicio cubre{" "}
                    <b>100 millas alrededor de Orlando, Jacksonville y Tampa</b>
                    , garantizando asistencia en carretera rápida y confiable
                    siempre que la necesite.
                  </>
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} textAlign="center">
              <Typography>
                <RestoreIcon sx={{ fontSize: 50 }} />
                <br />
                <b style={{ fontFamily: "Ethnocentric", fontSize: 30 }}>24/7</b>
                <b
                  style={{
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    paddingLeft: "5px",
                  }}
                >
                  service
                </b>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                {lang === "EN" ? (
                  <>
                    We offer <b>24/7 roadside assistance</b>, providing you with
                    peace of mind knowing that help is{" "}
                    <b>available anytime, day or night</b>.
                  </>
                ) : (
                  <>
                    Ofrecemos{" "}
                    <b>asistencia en carretera las 24 horas del día</b>, los 7
                    días de la semana, brindándole la tranquilidad de saber que
                    la ayuda está{" "}
                    <b>disponible en cualquier momento, día o noche</b>.
                  </>
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} textAlign="center">
              <Typography>
                <DepartureBoardIcon sx={{ fontSize: 50 }} />
                <br />
                <b style={{ fontFamily: "Ethnocentric", fontSize: 30 }}>5</b>
                <b
                  style={{
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    paddingLeft: "5px",
                  }}
                >
                  + trucks in service
                </b>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                {lang === "EN" ? (
                  <>
                    We have{" "}
                    <b>over 5 trucks available for roadside assistance</b> each
                    day, ensuring quick and reliable support whenever you need
                    it.
                  </>
                ) : (
                  <>
                    Contamos con{" "}
                    <b>
                      más de 5 camiones disponibles para asistencia en carretera
                    </b>{" "}
                    cada día, asegurando un soporte rápido y confiable cuando lo
                    necesite.{" "}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default StatisticsSection;
