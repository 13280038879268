import React from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";
import CallButtons from "../../components/CallButtons";
import RedTriangle from "../../assets/webp/red-triangle.webp";
import RedTriangle2 from "../../assets/webp/red-triangle-2.webp";
import NoCrashIcon from "@mui/icons-material/NoCrash";

const LocationSection = () => {
  const { lang } = useUiStore();

  

  function createData(cities1, cities2) {
    return { cities1, cities2 };
  }

  const rows = [
    createData("Ocala", "Poinciana"),
    createData("DeLand", "Pine Hills"),
    createData("Deltona", "Titusville"),
    createData("Orlando", "Haines City"),
    createData("Sanford", "Pine Castle"),
    createData("Clermont", "Williamsburg"),
    createData("Lakeland", "Winter Haven"),
    createData("Davenport", "Daytona Beach"),
    createData("Kissimmee", "Altamonte Springs"),
    createData("Melbourne", "and more..."),
  ];

  return (
    <>
      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair Location
      </Typography>

      {/* Desktop Image View */}
      <Box
        alt="Mechanical Service about us banner"
        title="Mechanical Service about us banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          {lang === "EN" ? <>Service Area</> : <>Área de Servicio</>}
        </Typography>
        <CallButtons />
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle2});`,
          backgroundPosition: "center right",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb: 10,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 40, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          {lang === "EN" ? <>Service Area</> : <>Área de Servicio</>}
        </Typography>
        <CallButtons />
      </Box>

      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }} spacing={5}>
          {/* Title */}
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric",
              }}
            >
              {lang === "EN" ? "List of cities" : "Lista de ciudades"}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric",
                },
              }}
            >
              {lang === "EN" ? "List of cities" : "Lista de ciudades"}
            </Typography>
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
            {/* Subtitle Desktop View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
                mb: 10,
              }}
            >
              {lang === "EN" ? (
                <>
                  <b>
                    Explore the cities that are part of our service network!
                  </b>{" "}
                  If your location is not on the list, <b>stay tuned!</b>
                  <br />
                  We are continuously working to{" "}
                  <b style={{ color: "#D69E48" }}>
                    expand and reach more places to serve you better.
                  </b>
                </>
              ) : (
                <>
                  <b>
                    ¡Descubre las ciudades que forman parte de nuestra red de
                    atención!
                  </b>{" "}
                  Si tu ubicación no está en la lista, <b>¡estate atento!</b>
                  <br />
                  Estamos trabajando constantemente para{" "}
                  <b style={{ color: "#D69E48" }}>
                    expandirnos y llegar a más lugares para servirte mejor.
                  </b>
                </>
              )}
            </Typography>

            {/* Subtitle Tablet and Phone View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                mb: 5,
              }}
            >
              {lang === "EN" ? (
                <>
                  <b>
                    Explore the cities that are part of our service network!
                  </b>{" "}
                  If your location is not on the list, <b>stay tuned!</b>
                  <br />
                  We are continuously working to{" "}
                  <b style={{ color: "#D69E48" }}>
                    expand and reach more places to serve you better.
                  </b>
                </>
              ) : (
                <>
                  <b>
                    ¡Descubre las ciudades que forman parte de nuestra red de
                    atención!
                  </b>{" "}
                  Si tu ubicación no está en la lista, <b>¡estate atento!</b>
                  <br />
                  Estamos trabajando constantemente para{" "}
                  <b style={{ color: "#D69E48" }}>
                    expandirnos y llegar a más lugares para servirte mejor
                  </b>
                  .
                </>
              )}
            </Typography>
          </Grid>

          {/* Google map */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="center">
            <TableContainer
              component={Paper}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{ backgroundColor: "#2f302f", color: "#FFCB68" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Ethnocentric",
                        }}
                      >
                        List of Cities
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.cities1}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={
                        index % 2
                          ? { background: "#EAEDED" }
                          : { background: "white" }
                      }
                    >
                      <TableCell component="th" scope="row" sx={{ pl: 10 }}>
                        <NoCrashIcon /> {row.cities1}
                      </TableCell>
                      <TableCell>
                        <NoCrashIcon /> {row.cities2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{ backgroundColor: "#2f302f", color: "#FFCB68" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Ethnocentric",
                        }}
                      >
                        List of Cities
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.cities1}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={
                        index % 2
                          ? { background: "#EAEDED" }
                          : { background: "white" }
                      }
                    >
                      <TableCell component="th" scope="row" sx={{ pl: 2 }}>
                        <NoCrashIcon /> {row.cities1}
                      </TableCell>
                      <TableCell sx={{ pl: 2 }}>
                        <NoCrashIcon /> {row.cities2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Google map */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="center">
            <iframe
              src="https://www.google.com/maps/d/u/5/embed?mid=1RowMPaU3T37zi9yff13Yhe4L787sCBM&ehbc=2E312F"
              width="100%"
              height="400"
              sx={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>

          <Grid item xs={12} sx={{ mt: 5 }} textAlign="center">
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>&#8592;&nbsp;&nbsp; Go to home</>
                ) : (
                  <>&#8592;&nbsp;&nbsp; Volver a página principal</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LocationSection;
